import { useTranslation } from 'react-i18next';

export default () => {
  /*---------------------筛选状态--------------------------------*/
  //获取国际化函数
  const { t } = useTranslation();

  // 方向筛选
  /**
   * 二层网络Layer2、人工智能AI、元宇宙Metaverse、链游GameFi
   */

  //交易类型 限价/市价
  const tradeTypeOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('进行中'),
      value: '1',
    },
    {
      text: t('历史'),
      value: '2',
    },
  ];

  return {
    tradeTypeOption,
  };
};
