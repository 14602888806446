import PullToRefresh from '@/components/base/basePullToRefresh';
import IEOCard from './IEOCard';

export default ({
  IEOList,
  IEOTotal,
  IEOError,
  IEOLoading,
  selectedTab,
  IEOMore,
  ...resetPageProps
}: any) => {
  return (
    <PullToRefresh
      loadMore={IEOMore!}
      total={IEOTotal!}
      list={IEOList!}
      error={IEOError}
      loading={IEOLoading!}
      {...resetPageProps}
    >
      {IEOList?.map((item: any,index:number) => {
        return (
          <div key={item?.id}>
            <IEOCard selectedTab={selectedTab} item={item} index={index} />
          </div>
        );
      })}
    </PullToRefresh>
  );
};
