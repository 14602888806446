import PageEnum from '@/enums/pageEnum';
import OrderCardItem from '@/pages/order/index/components/OrderCardItem';
import { BulkStatusEnum, OrderTypeEnum } from '@/pages/order/index/type';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

//大宗

export default ({ selectedTab, item, isHistory, activeKey, index }: any) => {
  const { t } = useTranslation();

  //申购状态
  const SubscribeEnum: any = {
    0: t('待审核'),
    1: t('审核通过'),
    2: t('审核失败'),
    3: t('进行中'),
    4: t('已结算'),
    5: t('结算失败'),
  };

  // 状态颜色
  const statusColor = (status: number) => {
    if ([0, 3].includes(status)) return 'text-[#3491FA] bg-[#E8F7FF]';
    if ([2, 5].includes(status)) return 'text-[#F53F3F] bg-[#FFECE8]';
    if ([1].includes(status)) return 'text-[#00B42A] bg-[#E8FFEA]';
    if ([4].includes(status)) return 'text-[#1D2129] bg-[#F2F3F5]';
  };

  // 获取是否通过
  const isApproved = useMemo(() => {
    if ([1, 3, 4, 5].includes(item?.status)) return true;
    return false;
  }, [item?.status]);

  console.log(isApproved, item?.status, 'item?.status');

  const { joinSymbol, getMoneyLength } = useModel('exchange');
  /*---------------------------历史----------------------------------------------*/
  const historyInfoArray = [
    {
      label: t('申购数量'),
      value: item?.buyNum,
    },
    {
      label: t('申购金额'),
      value: joinSymbol({
        price: RenderUtil.FormatAmount(
          item?.buyAmount,
          item?.priceAccuracy ?? 2,
        ),
        currency: item?.coinName,
      }),
    },
    {
      label: t('发行价格'),
      value: joinSymbol({
        price: RenderUtil.FormatAmount(
          item?.price,
          getMoneyLength(item?.coinName),
        ),
        currency: item?.coinName,
      }),
    },
    {
      label: t('中签数量'),
      value: item?.winningLotsNum,
    },
    {
      label: t('已成交数量'),
      value: item?.transactionNum,
    },
    +item?.unpaidAmount !== 0
      ? {
          label: t('需要缴纳金额'),
          value: joinSymbol({
            price: RenderUtil.FormatAmount(
              item?.unpaidAmount,
              getMoneyLength(item?.coinName),
            ),
            currency: item?.coinName,
          }),
        }
      : null,
    {
      label: t('已成交金额'),
      value: joinSymbol({
        price: RenderUtil.FormatAmount(
          item?.transactionAmount,
          getMoneyLength(item?.coinName),
        ),
        currency: item?.coinName,
      }),
    },
    {
      label: t('结束日期'),
      value: RenderUtil.formatDate(item?.stopBuyTime,"YYYY-MM-DD HH:mm:ss"),
    },
    {
      label: t('结算日期'),
      value: RenderUtil.formatDate(item?.settlementTime,"YYYY-MM-DD HH:mm:ss"),
    },
  ].filter((item) => item !== null);
  /*---------------------------进行中----------------------------------------------*/
  let infoArray: any = [
    {
      label: t('申购数量'),
      value: item?.buyNum,
    },
    {
      label: t('申购金额'),
      value: joinSymbol({
        price: RenderUtil.FormatAmount(
          item?.buyAmount,
          getMoneyLength(item?.coinName),
        ),
        currency: item?.coinName,
      }),
    },
    {
      label: t('发行价格'),
      value: joinSymbol({
        price: RenderUtil.FormatAmount(
          item?.price,
          getMoneyLength(item?.coinName),
        ),
        currency: item?.coinName,
      }),
    },
    {
      label: t('中签数量'),
      value: isApproved ? item?.winningLotsNum : '--',
    },
    {
      label: t('已成交数量'),
      value: isApproved ? item?.transactionNum : '--',
    },

    {
      label: t('已成交金额'),
      value: isApproved
        ? joinSymbol({
            price: RenderUtil.FormatAmount(
              item?.transactionAmount,
              getMoneyLength(item?.coinName),
            ),
            currency: item?.coinName,
          })
        : '--',
    },
    +item?.unpaidAmount !== 0
      ? {
          label: t('需要缴纳金额'),
          value: joinSymbol({
            price: RenderUtil.FormatAmount(
              item?.unpaidAmount,
              getMoneyLength(item?.coinName),
            ),
            currency: item?.coinName,
          }),
        }
      : null,
    {
      label: t('结束日期'),
      value: RenderUtil.formatDate(item?.stopBuyTime,"YYYY-MM-DD HH:mm:ss"),
    },
    {
      label: t('结算日期'),
      value: RenderUtil.formatDate(item?.settlementTime,"YYYY-MM-DD HH:mm:ss"),
    },
  ].filter((item) => item !== null);

  return (
    <OrderCardItem
      item={item}
      infoArray={
        (selectedTab === BulkStatusEnum.HISTORY && isHistory === undefined) ||
        isHistory
          ? historyInfoArray
          : infoArray
      }
      itemIndex={index}
      cardWrapper={
        (selectedTab === BulkStatusEnum.HISTORY && isHistory === undefined) ||
        isHistory
          ? 'flexBetween'
          : 'gridCol3'
      }
      title={
        <div className="mt-1 flex items-center  gap-1">
          <div>{item?.alias}</div>
          <div
            className={`py-[2px] px-1 text-center  rounded-[5px]  text-[12px]  ${statusColor(
              item.status,
            )}`}
          >
            {SubscribeEnum[item?.status]}
          </div>
        </div>
      }
      onClickCard={() => {
        history.push(
          `${PageEnum.CONTRACT_ORDER_DETAIL}/${item.id}?type=${OrderTypeEnum.IEO}&coinName=${item?.coinName}&activeKey=${activeKey}
            &targetUrl=${PageEnum.ORDER}&selectedTab=${selectedTab}`,
        );
      }}
    />
  );
};
