import { IconParkOutlineRight } from '@/assets/icons/comm/IconParkOutlineRight';
import { Divider } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 自适应订单卡片（PC表格，移动端卡片）
 * */
export default ({
  item,
  infoArray,
  title,
  describe,
  itemIndex,
  onClickCard,
  icon,
  classNames = { base: '', divider: '',title:'' },
  cardWrapper = 'gridCol3',
  isShowIcon = true,
}: IProps) => {
  const renderInfoArray = useMemo(() => {
    return infoArray.filter((item) => item.condition !== false);
  }, [infoArray]);
  const { isMobile } = useModel('system');
  const { t } = useTranslation();

  return (
    <>
      {/*pc展示表头*/}
      {!isMobile && (
        <div
          key={item.id}
          className={`mb-5 w-full ${classNames?.base}`}
          onClick={() => {
            onClickCard?.(item);
          }}
        >
          {itemIndex === 0 && (
            <div
              className="grid text-xs px-1 py-4 mb-4 bg-backgroundAuxiliaryColor"
              style={{
                gridTemplateColumns: `repeat(${
                  renderInfoArray.length + 1
                }, 1fr)`,
              }}
            >
              {<div className="px-3 break-words">{t('符号')}</div>}
              {renderInfoArray.map((item) => (
                <div className="px-3 break-words">{item?.label}</div>
              ))}
            </div>
          )}

          <div
            className="grid py-4"
            style={{
              gridTemplateColumns: `repeat(${renderInfoArray.length + 1}, 1fr)`,
            }}
          >
            <div className="mr-1 w- full break-words">{title}</div>
            {renderInfoArray.map((item) => (
              <div className="px-3 break-words">{item?.value}</div>
            ))}
          </div>
        </div>
      )}
      {/*移动端展示卡片*/}
      {isMobile && (
        <div
          key={item.id}
          className={`mb-5 ${classNames?.base}`}
          onClick={() => {
            onClickCard?.(item);
            return false;
          }}
        >
          {/* 订单头部 */}
          <div className={`flex justify-between items-center ${classNames?.title} `}>
            <div>
              <div className={`flex items-center `}>
                <div className="mr-1">{title}</div>
              </div>
              {describe && (
                <div className="text-iconFontColor text-xs truncate ">
                  {describe}
                </div>
              )}
            </div>
            {isShowIcon && (
              <span>
                {!icon && (
                  <IconParkOutlineRight className="text-auxiliaryTextColor flex items-center text-xs" />
                )}
                {icon}
              </span>
            )}
          </div>
          {/*进行中内容*/}
          <div className="mt-3">
            {cardWrapper === 'gridCol3' ? (
              <div
                className={`grid grid-cols-3 text-xs gap-x-[18px] justify-between pt-1`}
              >
                {/* 渲染一行三列布局 */}
                {renderInfoArray?.map((item: any, index: number) => {
                  return (
                    <div
                      key={item.label}
                      className={` w-full break-words pb-2 text-xs ${
                        (index + 1) % 3 === 0 ? 'text-right' : 'text-left'
                      }`}
                    >
                      <div className="text-auxiliaryTextColor">
                        {item.label}
                      </div>
                      <div
                        className={`text-backContrastColor w-full break-words text-xs ${
                          item?.extraClass || ''
                        } `}
                      >
                        {item.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {renderInfoArray?.map((item: any, index: number) => {
                  return (
                    <div
                      className="flex justify-between pb-1 text-xs"
                      key={item.label}
                    >
                      <span className="text-auxiliaryTextColor">
                        {item?.label}
                      </span>
                      <span className="text-backContrastColo ">
                        {item?.value}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <Divider
            className={`my-4 !bg-backgroundAuxiliaryTextColor ${classNames?.divider}`}
          />
        </div>
      )}
    </>
  );
};

interface IProps {
  // 索引
  itemIndex: number;
  // 订单数据
  item: any;
  // 生成键值对
  infoArray: {
    label: string | ReactNode;
    value: string | number | ReactNode;
    condition?: boolean;
    extraClass?: string;
  }[];
  // 头部小图标
  icon?: ReactNode;
  // 卡片标题
  title: ReactNode | string;
  // 卡片副标题
  describe?: ReactNode | string;
  // 点击卡片
  onClickCard?: (data?: any) => void;
  // 自定义样式
  classNames?: {
    // 卡片
    base?: string;
    // 分割线
    divider?: string;
    // 标题
    title?: string;
  };
  // 卡片展示样式（三栏布局（grid），左右布局（flex））
  cardWrapper?: 'gridCol3' | 'flexBetween';
  // 是否需要展示icon
  isShowIcon?: boolean;
}
