// 订单类型
import { ContractListTypeEnum, GetCoinListTypeEnum, StockListTypeEnum } from '@/enums/businessEnum';

export const OrderTypeEnum = {
  //全部
  ALL: '0',
  //虚拟币
  CRYPTOCURRENCY:
    GetCoinListTypeEnum.CONTRACT + ContractListTypeEnum.CRYPTOCURRENCY,
  //IPO
  IPO: GetCoinListTypeEnum.STOCK + StockListTypeEnum.IPO,
  //IEO
  IEO: GetCoinListTypeEnum.CONTRACT + StockListTypeEnum.IEO,
  //配售
  PLACING: GetCoinListTypeEnum.CONTRACT + StockListTypeEnum.DISTRIBUTION,
  //大宗
  BULK_STOCKS: GetCoinListTypeEnum.STOCK + StockListTypeEnum.BULK_STOCKS,
};

//大宗
export enum BulkStatusEnum {
  // 持仓中
  IN_POSITION = '0',
  //进行中
  ON_COMMISSION = '1',
  //历史
  HISTORY = '2',
}
