import React from 'react';
import type { SVGProps } from 'react';

export function TeenyiconsDownSolid(props: SVGProps<SVGSVGElement>) {
  return (<svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.91938 0H1.08062C0.242119 0 -0.224054 0.969932 0.299756 1.6247L2.21913 4.02391C2.61946 4.52432 3.38054 4.52432 3.78087 4.02391L5.70024 1.62469C6.22405 0.969932 5.75788 0 4.91938 0Z"
        fill="currentColor" />
    </svg>
  );
}