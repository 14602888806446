import BaseSift from '@/components/base/baseSift';
import BaseTopNav from '@/components/base/baseTopNav';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { getIEOOrderApi } from '@/services/api/requestApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderIEOList from './components/OrderIEOList';
import useConstants from './useConstants';

//IEO
export enum IEOStatusEnum {
  //持仓中
  IN_POSITION = '0',
  //进行中
  ON_COMMISSION = '1',
  //历史
  HISTORY = '2',
}

// @ts-ignore
/**
 * ieo 订单页面,即是页面也是组件，可用自定义样式，也可以选中部分隐藏
 */
const OrderView = () => {
  // IEO contract组件Ref

  // ieo 请求参数
  const [ieoParams, setIeoParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
    partition: '1',
    classification: undefined,
  });

  // getIEOPageList ieo 请求接口
  const {
    list: IEOPageList,
    loading: IEOLoading,
    getList: getIEOList,
    total: IEOTotal,
    error: IEOError,
    ...resetPageProps
  } = usePaginationPage({
    fn: getIEOOrderApi,
    params: ieoParams,
    setParams: setIeoParams,
    manual: true,
    defaultLoading: true,
  });

  useEffect(() => {
    getIEOList();
  }, []);

  const { t } = useTranslation();

  const { tradeTypeOption } = useConstants();
  const [type, setType] = useState(tradeTypeOption[0]);

  useEffect(() => {
    setIeoParams((old: any) => {
      return {
        ...old,
        classification: type.value,
      };
    });
  }, [type]);

  /**订单过滤条件 */
  const filterList = [
    {
      options: tradeTypeOption,
      onChange: setType,
      current: type,
      title: t('类型'),
    },
  ];

  // 获得未读数字
  return (
    <div className={``}>
      <BaseTopNav title={t('IEO订单')} />
      <div className="flex px-3">
        {/*筛选 不同的类型 有不同的筛选条件*/}
        {filterList.map((item: any) => {
          return (
            <BaseSift
              key={item?.title}
              options={item.options}
              onChange={item.onChange}
              current={item.current}
              title={item.title}
            />
          );
        })}
      </div>

      <div className={`p-3 px-4 pb-16  min-h-[40vh] mt-[13px]`}>
        <>
          <OrderIEOList
            IEOList={IEOPageList}
            IEOTotal={IEOTotal}
            IEOError={IEOError}
            IEOLoading={IEOLoading}
            selectedTab={undefined}
            {...resetPageProps}
          />
        </>
      </div>
    </div>
  );
};

export default OrderView;
